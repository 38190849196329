import { GET_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import router from '@/router';

export default class StockListViewModel {
  constructor() {
    this.dataList = [];
    this.searchParams = {
      page: 0,
      pageSize: 10,
      direction: 'DESC',
    };
    this.paginationData = {
      totalCount: 0,
      totalPage: 0,
    };
    this.boardData = {
      title: '모의투자 목록',
      drawDataList: [
        {
          title: '기업로고',
          width: '74px',
          value: 'stock_image_url',
          img: {
            size: 'contain',
            width: '36px',
            height: '36px',
            position: {
              x: 'center',
              y: 'center',
            },
          },
        },
        {
          title: '기업명(KR)',
          width: '210px',
          value: 'name',
          isAlignLeft: true,
        },
        {
          title: '기업명(EN)',
          width: '190px',
          value: 'eng_name',
          isAlignLeft: true,
        },
        {
          title: '종목코드',
          width: '',
          value: 'stock_code',
          isAlignLeft: true,
        },
        {
          title: '상태',
          width: '90px',
          value: 'status',
          filter: {
            name: 'convertIdToText',
            value: 'common_status',
          },
        },
        {
          title: '모의투자유무',
          width: '100px',
          value: 'is_game',
          filter: {
            name: 'convertIdToText',
            value: 'common_boolean',
          },
        },
      ],
      option: {
        isTotal: true,
        isSize: true,
        rowIdValue: 'stock_code',
      },
    };
    this.callCount = 0;
    this.successCount = 0;
    this.failData = []
  }
  init(query) {
    const { pageSize, page } = query;
    if (pageSize) {
      this.searchParams.pageSize = Number(pageSize);
    }
    if (page) {
      this.searchParams.page = Number(page);
    }
    this.getStocksList();
  }
  onSearch() {
    this.getStocksList();
  }
  onClickStocksRegister() {
    router.push({ name: 'STOCK_STOCKS_REGISTER' });
  }
  onClickRow(rowId) {
    router.push({
      name: 'STOCK_STOCKS_MODIFY',
      params: { id: rowId },
      query: {
        pageSize: this.searchParams.pageSize,
        page: this.searchParams.page,
      },
    });
  }

  getStocksList() {
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.STOCK_STOCKS_LIST}${query}`;
    GET_REQUEST(path).then(
      success => {
        const resultData = success.data.body;
        this.dataList = resultData.data;
        this.paginationData.totalCount = resultData.total;
        this.paginationData.totalPage = resultData.totalPage;
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
}